import React from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

import bg from "../images/misc/invesco.png";

function LearningObjective({ moduleName } : { moduleName: string }) {
  return (
    <div>
      <img src={bg} alt="" style={{ width: '400px', height: 'auto', }} />
      <Typography variant="h3" sx={{ fontWeight: 'bold', paddingBottom: '20px' }}>{moduleName}</Typography>
    </div>
  );
}

export default LearningObjective;