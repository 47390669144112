import { Amplify } from 'aws-amplify';

const isDevelopment = process.env.REACT_APP_ENV === 'development';

Amplify.configure({
    Auth: {
        region: 'us-east-2', // your AWS region
        userPoolId: 'us-east-2_DrYbaocQQ', // Your Cognito User Pool ID
        userPoolWebClientId: '4qhto07568k6dgbua7pi8vfn0k', // Your Cognito App Client ID
        mandatorySignIn: true,
        oauth: {
            domain: 'mf-dev-lab.auth.us-east-2.amazoncognito.com',
            scope: ['openid'],
            // redirectSignIn: 'http://localhost:3001/',
            // redirectSignOut: 'http://localhost:3001',
            redirectSignIn: isDevelopment
              ? 'http://localhost:3001/modules'
              : 'https://viewer-dev-lab.mothandflamevr.com',
            redirectSignOut: isDevelopment
              ? 'http://localhost:3001'
              : 'https://viewer-dev-lab.mothandflamevr.com/modules',
            responseType: 'code', // or 'token', the grant type you have configured in Cognito
        },
    }
});
