import React from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';

const Textcontainer = styled(Box)({
  textAlign: 'center',
  maxWidth: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  borderRadius: '15px',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  padding: '40px',
  gap: '20px',
});

const FeedbackOption = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '5px',
}));

function FeedbackOptions() {
  const [selectedOption, setSelectedOption] = React.useState<string | null>(null);

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    console.log('Selected:', option);
  };

  return (
    <Textcontainer>
      <Typography variant="h5">
        What makes you hesitant to recommend this virtual learning experience for use by others?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '20px',
          mt: 2,
        }}
      >
        {['Content', 'Logistics & distribution', 'Technical issues', 'Not engaging', 'Physical discomfort', 'Other'].map((option) =>
          <FeedbackOption
            key={option}
            variant={selectedOption === option ? "contained" : "outlined"}
            color="primary"
            onClick={() => handleOptionSelect(option)}
          >
            {option}
          </FeedbackOption>
        )}
      </Box>
    </Textcontainer>
  );
}

export default FeedbackOptions;
