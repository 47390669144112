import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, CssBaseline, Box, Avatar, TextField, Typography, FormControlLabel, Button, Grid, Link, Checkbox } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

async function loginUser({ username, password }: { username: string, password: string }) {
  try {
      const user = await Auth.signIn(username, password);
      // If successful, user object will have all the details of the authenticated user.
      // You might want to extract the JWT tokens or any other details you need here.
      return user;
  } catch (error) {
      console.error('Error logging in:', error);
      throw error;  // or handle the error as per your needs
  }
}

const signInWithGoogle = async () => {
  try {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  } catch (error) {
    console.error('Error signing in with Google', error);
  }
};

export default function Login({ setLogin }: { setLogin: (token: string) => void }) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
        const user = await loginUser({ username, password });

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // This is a simplified example; in a real-world scenario, you might show a modal
            // or navigate to another component to get the new password from the user
            const newPassword: string | null = prompt('Enter new password:');
            if (newPassword !== null) {
              // Here you'll need to use Amplify's completeNewPassword method
              const authenticatedUser = await Auth.completeNewPassword(user, newPassword);
              const token = authenticatedUser.signInUserSession.idToken.jwtToken;
              setLogin(token);
            }
        } else {
            const token = user.signInUserSession.idToken.jwtToken;
            setLogin(token);
        }
        navigate('/');
    } catch (error) {
        console.error('Login error:', error);
        // Handle the error in the UI (e.g., display an error message)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            value={username}
            onChange={e => setUserName(e.target.value)}
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email Address"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Button onClick={signInWithGoogle} fullWidth sx={{ mt: 3, mb: 2 }} variant="contained">
              Sign in with Google
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}