import React from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';

const Textcontainer = styled(Box)({
  textAlign: 'left',
  maxWidth: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
})

function Confidence() {
  const [value, setValue] = React.useState();

  const handleChange = (value) => {
    setValue(value);
    console.log('change', value)
  };

  return (
    <Textcontainer>
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 5 }}>Now that you have completed the module, how confident do you feel dealing with these kinds of issues?</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          '& > *': {
            m: 1,
          },
        }}
      >
        {[1,2,3,4,5,6,7,8,9,10].map((value) =>
          <Button
            variant="contained"
            onClick={() => handleChange(value)}
            key={value}
            sx={{
              m: 1,
              width: '40px',
              height: '60px',
              borderRadius: '5px'
            }}
          >
            {value}
          </Button>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'lightGrey', py: 1 }}>
        <div>Not confident</div>
        <div>Extremely confident</div>
      </Box>
    </Textcontainer>
  );
}

export default Confidence;
