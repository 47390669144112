import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import './App.css';
import BrowserViewer from './BrowserViewer';
import Login from './components/Login';
import './aws-config';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  }

  function handleLogout() {
    setIsAuthenticated(false); // You might want to clear any stored tokens here as well.
  }

  function setLogin() {
    setIsAuthenticated(true); // Assuming you have managed the token within the Login component.
  }

  if (!isAuthenticated) {
    return <Login setLogin={setLogin} />
  }

  return (
    <div>
      <BrowserViewer handleLogout={handleLogout} />
    </div>
  );
}

export default App;