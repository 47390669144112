import React from "react";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline"
import {
  Box,
  Paper,
  Container,
  Drawer,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import ViewerIntro from './components/ViewerIntro';
import VoiceTest from './components/VoiceTest';
import LearningObjective from './components/LearningObjective';
import CharacterIntro from './components/CharacterIntro';
import Confidence from './components/Confidence';
import Results from './components/Results';
import ExperienceViewer from "./components/ExperienceViewerTwo";
import ModuleSelector from './components/ModuleSelector';
import Layout from './components/Layout';
import ExperienceIntro from './components/ExperienceIntro';
import Intro from './components/Intro';
import Feedback from './components/Feedback';
import Recommendation from './components/Recommendation';
import FeedbackOptions from './components/FeedbackOptions';

// Importing the styling of App component

import './BrowserViewer.css';

function BrowserViewer({ handleLogout } : { handleLogout: () => void }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [pathA, setPathA] = React.useState(null);
  const [pathB, setPathB] = React.useState(null);
  const [pathC, setPathC] = React.useState(null);
  const [summary, setSummary] = React.useState('');
  const [learningObjective, setLearningObjective] = React.useState('');
  const [communicationStyleA, setCommunicationStyleA] = React.useState('');
  const [communicationStyleB, setCommunicationStyleB] = React.useState('');
  const [communicationStyleC, setCommunicationStyleC] = React.useState('');
  const [moduleName, setModuleName] = React.useState('');
  const [characterName, setCharacterName] = React.useState('');
  const [videoUrls, setVideoUrls] = React.useState({});
  const [videosLoading, setVideosLoading] = React.useState(true);

  const retrieveModuleData = async (module: any) => {
    setVideosLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/retrieve_module_metadata?module_name=${module}`)
      .then(response => response.json())
      .then(json => {
        // const scriptText = JSON.parse(json['script']);
        const scriptText = json['script'];
        if (scriptText) {
          setPathA(scriptText['pathA']['moments']);
          setPathB(scriptText['pathB']['moments']);
          setPathC(scriptText['pathC']['moments']);
        };
        const learningObjective: string = json['learningObjective'];
        if (learningObjective) {
          setLearningObjective(learningObjective);
        }
        const communicationStyleA: string = json['communicationStyleA'];
        if (communicationStyleA) {
          setCommunicationStyleA(communicationStyleA);
        }
        const communicationStyleB: string = json['communicationStyleB'];
        if (communicationStyleB) {
          setCommunicationStyleB(communicationStyleB);
        }
        const communicationStyleC = json['communicationStyleC'];
        if (communicationStyleC) {
          setCommunicationStyleC(communicationStyleC);
        }
        const characterName = json['characterName'];
        if (characterName) {
          setCharacterName(characterName);
        }
        setSummary(json['summary']);
        const moduleName = json['moduleName'];
        if (moduleName) {
          setModuleName(moduleName);
        }
        // setMomentNumber(1);
      })
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const wrapLayout = (step: any) => <Layout
    handleLogout={handleLogout}
    toggleDrawer={toggleDrawer}
    drawerOpen={drawerOpen}
    activeStep={activeStep}
    handleBack={handleBack}
    handleNext={handleNext}
  >
    {step}
  </Layout>

  const steps = [
    wrapLayout(<ModuleSelector retrieveModuleData={retrieveModuleData} />),
    wrapLayout(<VoiceTest />),
    wrapLayout(<Intro moduleName={moduleName} />),
    wrapLayout(<LearningObjective learningObjective={learningObjective} summary={summary} />),
    wrapLayout(<Confidence promptString={'How confident are you in'} questionString={''} />),
    wrapLayout(<ExperienceIntro />),
    wrapLayout(<CharacterIntro characterName={characterName} communicationStyleA={communicationStyleA} communicationStyleB={communicationStyleB} communicationStyleC={communicationStyleC} />),
    <ExperienceViewer handleNext={handleNext} moduleName={moduleName} />,
    wrapLayout(<Feedback />),
    wrapLayout(<Confidence promptString={'Now that you have completed the experience, how confident are you in'} questionString={''} />),
    wrapLayout(<Recommendation />),
    wrapLayout(<Confidence promptString={'How likely are you to recommend this experience to others who need to learn the same skills?'} questionString={''} />),
    wrapLayout(<FeedbackOptions />),
    wrapLayout(<Results />),
  ]

  return steps[activeStep];
}

export default BrowserViewer;