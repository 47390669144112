import React from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

const Textcontainer = styled(Box)({
  textAlign: 'left',
  maxWidth: '80%',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-around',
  height: '100%',
  margin: '10%',
  // overflow: 'hidden',
  marginRight: '40%'
})

const Skill = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    border: '2px solid gray',
    padding: '20px',
    borderRadius: '5px',
    margin: '5px'
  })

function LearningObjective({
  learningObjective,
  summary
}: { learningObjective: string, summary: string }) {
  return (
    <Textcontainer>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', paddingBottom: '20px' }}>Skills for Success</Typography>
        <Typography>{summary}</Typography>
        <Skill>
          <CheckCircleIcon sx={{ color: green[400], marginRight: '10px' }} />
          <Typography>{learningObjective}</Typography>
        </Skill>
      </Box>
    </Textcontainer>
  );
}

export default LearningObjective;