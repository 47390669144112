import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import {
  Container,
  Button,
  CircularProgress
} from '@mui/material';
import levenshtein from "js-levenshtein";

import './ExperienceViewer.css';

import Webcam from './Webcam';

const DialogButton = styled(Button)({
  width: '100%',
  backgroundColor: 'transparent',  // Add this line
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Change the color and opacity as needed
  },
})

const StyledVideo = styled('video')({
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1
});

const WebcamContainer = styled(Container)({
  position: 'absolute',
  top: '5%',
  right: '5%',
  width: '20%',
  height: 'auto',
  zIndex: 2
});

export type Moment = {
  moment: string;
  learner_text: string;
  character_text: string;
  feedback: string;
};

export type Path = {
  moments: Moment[];
};

interface CustomSpeechRecognitionEvent {
  results: SpeechRecognitionResultList;
  [key: string]: any; // for other potential properties
}

function ExperienceViewer({ handleNext, moduleName }: { handleNext: () => void, moduleName: string }) {
  const [momentNumber, setMomentNumber] = React.useState(0);
  const [listeningLoopSrc, setListeningLoopSrc] = React.useState(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/listening_loop.mp4`);
  const [transcript, setTranscript] = React.useState("");
  const [isListening, setIsListening] = React.useState(false);
  const [isMainVideoVisible, setIsMainVideoVisible] = React.useState(true);
  // const [currentVideo, setCurrentVideo] = React.useState('http://localhost:8000/get_video?module_name=TestModule1&video=0.mp4');
  const [currentVideo, setCurrentVideo] = React.useState(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/0.mp4`);
  const [isListeningLoopVisible, setIsListeningLoopVisible] = React.useState(false);
  const [moduleScript, setModuleScript] = React.useState(null);
  const [pathA, setPathA] = React.useState<Path | null>(null);
  const [pathB, setPathB] = React.useState<Path | null>(null);
  const [pathC, setPathC] = React.useState<Path | null>(null);
  const [pathAOption, setPathAOption] = React.useState<string | null>(null);
  const [pathBOption, setPathBOption] = React.useState<string | null>(null);
  const [pathCOption, setPathCOption] = React.useState<string | null>(null);
  const [videosLoading, setVideosLoading] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const fetchModuleScript = async () => {
      try {
        console.log(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/module_metadata.json`);
        const moduleMetadata = await fetch(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/module_metadata.json`).then(res => res.json());
        const moduleScript = moduleMetadata.moduleScript;

        setModuleScript(moduleScript);
        setPathA(moduleScript.pathA);
        setPathB(moduleScript.pathB);
        setPathC(moduleScript.pathC);
      } catch (error) {
        console.error(error);
        // Handle the error appropriately here, perhaps setting some error state to show a user-friendly message.
      }
    };

    fetchModuleScript();
  }, []);

  useEffect(() => {
    if (momentNumber < 10) {
      setPathAOption(pathA && pathA.moments[momentNumber].learner_text);
      setPathBOption(pathB && pathB.moments[momentNumber].learner_text);
      setPathCOption(pathC && pathC.moments[momentNumber].learner_text);
    }
  }, [momentNumber, pathA, pathB, pathC]);

  // const proceed = (branch: string) => {
  //   // setCurrentVideo(`http://localhost:8000/get_video?module_name=TestModule1&video=${momentNumber + 1}_${branch}.mp4`);
  //   setIsListeningLoopVisible(true);
  //   setIsListening(false);
  //   setTimeout(() => {
  //     setCurrentVideo(`${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/${momentNumber + 1 }_${branch}.mp4`);
  //     setIsListeningLoopVisible(false);
  //   }, 1000);
  //   setMomentNumber(momentNumber + 1);
  // }

  const proceed = (branch: string) => {
    const nextVideoSrc = `${process.env.REACT_APP_ASSETS_URL}/modules/${moduleName}/${momentNumber + 1}_${branch}.mp4`;

    setIsListening(false);
    handleChoice(nextVideoSrc); // Use handleChoice to manage video transitions

    setTimeout(() => {
      setIsListeningLoopVisible(false);
      setMomentNumber(momentNumber + 1);
    }, 1000);
  }

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    // ... other recognition event handlers ...

    recognition.onresult = (event: CustomSpeechRecognitionEvent) => {
      const resultTranscript = event.results[0][0].transcript;
      setTranscript(resultTranscript);

      // Calculate the Levenshtein distance to each option
      const options = [pathA, pathB, pathC].map(path => path?.moments[0]?.learner_text || '');
      const distances = options.map((option) => levenshtein(option, resultTranscript));

      // Find the option with the smallest distance
      const minDistance = Math.min(...distances);
      const minIndex = distances.indexOf(minDistance);

      if (minIndex === 0) {
        proceed('A');
        setIsListening(false);
      } else if (minIndex === 1) {
        proceed('B');
        setIsListening(false);
      } else if (minIndex === 2) {
        proceed('C');
        setIsListening(false);
      }
    };

    if (isListening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    }
    // ... other code ...
  }, [isListening]);

  if (videosLoading) {
    return <CircularProgress size={150} style={{ position: 'absolute', bottom: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
  }

  const handleVideoEnd = () => {
    if (momentNumber === 10) {
      handleNext();
    } else {
      setIsListeningLoopVisible(true);
      setIsListening(true);
    }
  }

  const handleVideoPlay = () => {
    setIsListening(false);
    const videoElement = videoRef.current;
    if (videoElement) {
      setTimeout(() => {
        setIsListening(true);
      }, (videoElement.duration - 5) * 1000);
    }
  }

  const handleChoice = (nextVideoSrc: string) => {
    const videoElement = videoRef.current;
    const listeningLoopElement = document.querySelector('.video-listening-loop') as HTMLElement;

    if (videoElement) {
      // Put listening loop on top during transition
      if (listeningLoopElement) {
        listeningLoopElement.style.zIndex = '3';
      }

      // Fade out current video
      videoElement.classList.add('video-hidden');
      videoElement.classList.remove('video-visible');

      setTimeout(() => {
        // Once fade out is done, change source and start fade in
        videoElement.src = nextVideoSrc;
        videoElement.load();
        videoElement.classList.remove('video-hidden');
        videoElement.classList.add('video-visible');

        // Revert listening loop to be behind main video
        if (listeningLoopElement) {
          listeningLoopElement.style.zIndex = '1';
        }
      }, 1000);
    }
  }

  return (
    <div>
      <StyledVideo
        ref={videoRef}
        controls
        autoPlay
        preload="auto"
        key={currentVideo}
        onEnded={() => handleVideoEnd()}
        onPlay={handleVideoPlay}
        style={{ zIndex: isListeningLoopVisible ? 1 : 2 }} // Control z-index here
        className={isListeningLoopVisible ? 'video video-hidden' : 'video video-visible video-main'}
      >
        <source src={currentVideo} type="video/mp4" />
      </StyledVideo>
      <StyledVideo
        controls
        autoPlay
        preload="auto"
        key="loop"
        style={{ zIndex: isListeningLoopVisible ? 2 : 1 }} // Control z-index here
        className={isListeningLoopVisible ? 'video video-visible video-listening-loop' : 'video video-hidden video-listening-loop'}
        loop
      >
        <source src={listeningLoopSrc} type="video/mp4" />
      </StyledVideo>
      <Container
        style={{
          zIndex: 2,
          position: 'absolute',
          bottom: '20%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        <DialogButton variant="contained" onClick={() => proceed('A')}>{pathAOption}</DialogButton>
        <DialogButton variant="contained" onClick={() => proceed('B')}>{pathBOption}</DialogButton>
        <DialogButton variant="contained" onClick={() => proceed('C')}>{pathCOption}</DialogButton>
      </Container>
      <WebcamContainer
        style={{ position: 'absolute', display: 'block', width: '20%', height: 'auto', top: '5%', right: '5%' }}
      >
        <Webcam />
      </WebcamContainer>
    </div>
  )
}

export default ExperienceViewer;
