import React, { ReactNode } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Container,
  FormGroup,
  Drawer,
  IconButton,
  Button,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

// Import other necessary components and styles

interface LayoutProps {
  children: ReactNode;
  handleLogout: () => void;
  toggleDrawer: () => void;
  drawerOpen: boolean;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
}

const Background = styled(Container)({
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "5%",
  backgroundImage: "url(/path/to/your/background.jpg)",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
});

const Foreground = styled(Paper)({
  width: "100%",
  height: "75%",
  borderRadius: "20px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  overflow: "visible",
  padding: 0,
  backgroundColor: "rgb(200, 200, 200, 0.01)",
});

const Option = styled(Box)({
  padding: "20px",
  borderRadius: "5px",
  backgroundColor: "rgb(190, 190, 190)",
  margin: "5px",
  color: "black",
});

const CustomDrawer = styled(Drawer)({
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "25%",
    marginTop: "10px",
    marginX: "20px",
    transform: "translateX(20px)",
    padding: "25px",
    height: "calc(100% - 40px)",
    borderRadius: "10px",
    backgroundColor: "rgb(200, 200, 200)",
  },
});

const BackButton = styled(Button)({
  backgroundColor: "grey",
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  minWidth: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  "&:hover": {
    backgroundColor: "darkgrey",
  },
});

const NextButton = styled(Button)({
  backgroundColor: "blue",
  borderRadius: "35px",
  padding: "5px 20px",
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "darkblue",
  },
});

const theme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (max-width:600px)": {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});

const Layout = ({
  children,
  handleLogout,
  toggleDrawer,
  drawerOpen,
  activeStep,
  handleBack,
  handleNext,
}: LayoutProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {/* Logout Button */}
    <Box
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        p: 1,
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Button onClick={handleLogout} variant="contained" color="primary">
        Logout
      </Button>
    </Box>

    {/* Background and Drawer */}
    <Background>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          p: 1,
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      </Box>
      <CustomDrawer open={drawerOpen} sx={{ width: "%" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{ color: "black", fontWeight: "bold", fontSize: "40px" }}
        >
          Menu
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Option>
            <Typography sx={{ fontWeight: "bold" }}>
              Exit to main menu
            </Typography>
          </Option>
          <Option>
            <Typography sx={{ fontWeight: "bold" }}>
              Restart experience
            </Typography>
          </Option>
          <Option>
            <Typography sx={{ fontWeight: "bold" }}>Support</Typography>
          </Option>
          <Option>
            <Typography sx={{ fontWeight: "bold" }}>Sign out</Typography>
          </Option>
        </Box>
      </CustomDrawer>

      {/* Foreground */}
      <Foreground>{children}</Foreground>

      {/* Bottom Navigation */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: "7.5%",
          right: "7.5%",
          width: "85%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 5%",
          borderRadius: "30px",
          boxSizing: "border-box",
        }}
      >
        <BackButton disabled={activeStep === 0} onClick={handleBack}>
          ❮
        </BackButton>

        <NextButton onClick={handleNext}>Next &nbsp;&nbsp;❯</NextButton>
      </Box>
    </Background>
  </ThemeProvider>
);

export default Layout;
