import React from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';

const Textcontainer = styled(Box)({
  textAlign: 'center',
  maxWidth: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  borderRadius: '15px',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  padding: '40px',
  gap: '20px',
});

function RecommendationCard() {
  const [recommend, setRecommend] = React.useState<string | null>(null);

  const handleRecommendation = (value: string) => {
    setRecommend(value);
    console.log('Recommendation:', value);
  };

  return (
    <Textcontainer>
      <Typography variant="h5">
        Would you recommend this virtual learning experience to others who need to learn the same skills?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <Button
          variant={recommend === "Yes" ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleRecommendation("Yes")}
        >
          Yes
        </Button>
        <Button
          variant={recommend === "No" ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleRecommendation("No")}
        >
          No
        </Button>
      </Box>
    </Textcontainer>
  );
}

export default RecommendationCard;
