import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';


export default function ModuleSelector({ retrieveModuleData }) {
  const [moduleList, setModuleList] = React.useState([]);
  const [selectedModule, setSelectedModule] = React.useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/retrieve_module_list`
        );
        const json = await res.json();
        console.log(json);

        if (json.module_list) {
          // ensure you're referencing the right property from the response
          setModuleList(json.module_list.map(module => module.module_name));
        }
      } catch (error) {
        console.error("Failed to fetch module list:", error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (event) => {
    setSelectedModule(event.target.value);
    retrieveModuleData(event.target.value);
    navigate(`${location.pathname}?module=${event.target.value}`);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <FormControl variant="standard" sx={{ m: 1, width: '50%' }}>
        <InputLabel id="demo-simple-select-standard-label">Select Module</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selectedModule}
          onChange={handleChange}
          label="Module"
        >
          {moduleList.map(module => <MenuItem value={module}>{module}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}