import React, { useEffect, useState } from "react";
import { createTheme, styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  IconButton
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import { green } from '@mui/material/colors';
import levenshtein from "js-levenshtein";

const Textcontainer = styled(Box)({
  textAlign: 'left',
  maxWidth: '80%',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-',
  height: '100%',
  margin: '15%',
  marginRight: '40%',
  gap: '20px'
})

const VoiceOver = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '2px solid gray',
  padding: '10px',
  borderRadius: '5px',
})

const RecordButton = styled(IconButton)({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: 'grey'
})

const targetString = 'I can speak and my words are powerful.';

const styles = {
  container: {
    textAlign: 'left',
    maxWidth: '80%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '15%',
    marginRight: '40%',
    gap: '20px',
  },
  button: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: 'grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
  },
  micIcon: {
    color: 'green',
    fontSize: '24px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2em',
  },
  description: {
    fontSize: '1em',
  },
  voiceOver: {
    display: 'flex',
    alignItems: 'center',
    border: '2px solid gray',
    padding: '10px',
    borderRadius: '5px',
  },
  recordIcon: {
    margin: '0 10px',
    color: 'grey',
    fontSize: '24px',
  },
  quote: {
    fontWeight: 'bold',
  },
  smallPrint: {
    color: 'gray',
    padding: '50px 0',
    margin: '10px 0',
    fontSize: '0.8em',
  },
  transcript: {
    fontWeight: 'bold',
  },
  distance: {
    fontWeight: 'bold',
  },
};



function VoiceTest() {
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.onstart = () => {
      console.log("Voice is being recorded...");
    };

    recognition.onresult = (event) => {
      const resultTranscript = event.results[0][0].transcript;
      setTranscript(resultTranscript);
      const levDistance = levenshtein(targetString, resultTranscript);
      setDistance(levDistance);
      // setTranscript(event.results[0][0].transcript);
    };

    if (isListening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);

  const handleListen = () => {
    setIsListening(!isListening);
  };

  return (
    <Textcontainer>
      <RecordButton onClick={handleListen}>
        <MicIcon fontSize="large" sx={{ color: green[500] }} />
      </RecordButton>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Voice Test</Typography>
      <Typography variant="body1">This is a voice activated experience. Read the text below out loud, slowly, and clearly.</Typography>
      <VoiceOver sx={{ padding: '20px', marginX: '10px' }}>
        <RecordVoiceOverIcon sx={{ marginX: '10px', color: 'grey' }} fontSize="large" />
        <Typography sx={{ fontWeight: 'bold' }}>"I can speak and my words are powerful."</Typography>
      </VoiceOver>
      <Typography sx={{ color: 'gray', paddingY: '50xp', marginY: '10px' }}>Please accept the request to activate your device's microphone.</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>
        {transcript}
      </Typography>
      {distance !== null && (
        <Typography sx={{ fontWeight: 'bold' }}>
          Levenshtein distance: {distance}
        </Typography>
      )}
    </Textcontainer>
  );
  // return (
  //   <div style={styles.container}>
  //     <button style={styles.button} onClick={handleListen}>
  //       <i style={styles.micIcon} className="fa fa-microphone" />
  //     </button>
  //     <h4 style={styles.title}>Voice Test</h4>
  //     <p style={styles.description}>
  //       This is a voice activated experience. Read the text below out loud,
  //       slowly, and clearly.
  //     </p>
  //     <div style={styles.voiceOver}>
  //       <i style={styles.recordIcon} className="fa fa-comment-dots" />
  //       <p style={styles.quote}>
  //         "I can speak and my words are powerful."
  //       </p>
  //     </div>
  //     <small style={styles.smallPrint}>
  //       Please accept the request to activate your device's microphone.
  //     </small>
  //     <p style={styles.transcript}>
  //       {transcript}
  //     </p>
  //     {distance !== null && (
  //       <p style={styles.distance}>
  //         Levenshtein distance: {distance}
  //       </p>
  //     )}
  //   </div>
  // );
}

export default VoiceTest;